import {Store} from '@reduxjs/toolkit'
import {QueryClient} from '@tanstack/react-query'
import {FunctionComponent, JSX, ReactElement, ReactNode} from 'react'
import {createRoot} from 'react-dom/client'
import * as Final from 'react-final-form'
import {
  Falsish,
  Falsy,
  Nullish,
  falsish,
  falsy,
  notNullish,
  nullish,
  truish,
  truthy,
} from 'tizra'
import {CamelCase, EmptyObject} from 'type-fest'
import type {Hack, Hacks, HacksFromServer} from './hacks'

export {falsish, falsy, notNullish, nullish, truish, truthy}
export type {Falsish, Falsy, Nullish}

export type ValueOf<T> = T[keyof T]

export type Dict<V = unknown> = {[k: string]: V}

export type Req<T, N extends keyof T> = Omit<T, N> & Required<Pick<T, N>>

export type Opt<T, N extends keyof T> = Omit<T, N> & Partial<Pick<T, N>>

export type Never<T, N extends string | symbol | number> = Omit<T, N> & {
  [K in N]?: never
}

export type IfElse<T, U, K extends keyof T> = Req<T, K> | Never<U, K>

export type OmitTransientProps<T> = {
  [K in keyof T]: K extends `$${string}` ? never : T[K]
}

export type UnprefixTransientProps<T> = {
  [K in keyof T as K extends `$${infer K1}` ? K1 : K]: T[K]
}

export type UnprefixDataProps<T> = {
  [K in keyof T as K extends `data-${infer K1}` ? CamelCase<K1> : K]: T[K]
}

export type UnprefixStyledProps<T> = UnprefixDataProps<
  UnprefixTransientProps<T>
>

// Convert `const arr = ['a', 'b'] as const` to effectively string[]
// https://stackoverflow.com/a/43001581
export type Writeable<T> = {-readonly [P in keyof T]: T[P]}

// https://changelog.com/posts/the-react-reactnode-type-is-a-black-hole
// but with StrictReactNode[] instead of ReactNodeArray, and without allowing
// undefined.
//
// Update: fixed with React 18 types.
// https://solverfox.dev/writing/no-implicit-children/
export type StrictReactNode = ReactNode

// React.FC without children
export type FCx<P = Dict> = ChildlessFunctionComponent<P>
export type PropsWithoutChildren<P> = P & {children?: never}
export interface ChildlessFunctionComponent<P = Dict>
  extends FunctionComponent<P> {
  (props: PropsWithoutChildren<P>, context?: any): ReactElement<any, any> | null
}

export type {Hack, Hacks, HacksFromServer}

export interface SessionError {
  key: string
  reason: string
  message: string
}

export const isSessionError = (x: unknown): x is SessionError =>
  !!(x && typeof x === 'object' && 'message' in x)

/**
 * Supplied by quickstartMacros.ftl. Nothing should be missing because
 * quickstartVersions.ftl and quickstartMacros.ftl are always updated together,
 * whether hot deploy or full deploy.
 */
export interface BlockContextFromServer {
  debugFlags: string
  globalConfig: {[k: string]: any}
  hacks: HacksFromServer
  isMobileUserAgent: boolean
  location: string
  metaObject: unknown // unprocessed query API response
  metaType: string
  pageId: string
  pageObject: unknown // unprocessed query API response
  pubType: 'CMS' | 'LIVE' | 'STAGING'
  quickSearchFields: string
  renderedAt: string
  sessionErrors: SessionError | SessionError[] | EmptyObject
  successUrl: string
  tizraId: string
}

export interface BlockContext
  extends Omit<BlockContextFromServer, 'hacks' | 'sessionErrors'> {
  hacks: Partial<Hacks>
  sessionErrors: SessionError[]
}

/**
 * Supplied by quickstartMacros.ftl. Nothing should be missing because
 * quickstartVersions.ftl and quickstartMacros.ftl are always updated together,
 * whether hot deploy or full deploy.
 */
export interface AdminContextFromServer {
  debugFlags: string
  globalConfig: {[k: string]: any}
  hacks: string[]
  metaType: string
  metaTypes: string[]
  siteDisplayId: number
  // Nothing in cubchicken current supplies tizraId in the admin context, but
  // replaceField() looks for objectId in the surrounding form and converts it.
  tizraId?: string
}

export interface AdminContext extends Omit<AdminContextFromServer, 'hacks'> {
  global: boolean
  hacks: Partial<Hacks>
}

export interface GlobalMigratePropsInRender {
  context: BlockContext
}

export interface GlobalMigratePropsInAdmin<UG = unknown> {
  adminContext: AdminContext
  globalConfig: UG
}

// What gets passed to the enhancing wrapper
export type UnresolvedGlobalMigrateProps<UG = unknown> =
  | GlobalMigratePropsInRender
  | GlobalMigratePropsInAdmin<UG>

// The enhancing wrapper that pulls globalConfig from context before calling
// block-provided or default globalMigrate
export type GlobalMigrateWrapper<G, UG = unknown> = (
  props: UnresolvedGlobalMigrateProps<UG>,
) => G

// What gets passed to defaultGlobalMigrate
export type DefaultGlobalMigrateProps<UG = unknown> = {globalConfig: UG}

// What gets passed to globalMigrate by way of the enhancing wrapper
export type GlobalMigrateProps<UG = unknown> =
  UnresolvedGlobalMigrateProps<UG> & {
    globalConfig: UG
    hasHack: (s: Hack) => boolean
  }

export type GlobalMigrate<G, UG = unknown> = (
  props: GlobalMigrateProps<UG>,
) => G

export interface MigratePropsInRender<UC = unknown> {
  adminContext?: never
  context: BlockContext
  config: UC
}

export interface MigratePropsInAdmin<UC = unknown> {
  adminContext: AdminContext
  context?: never
  config: UC
}

// What gets passed to the enhancing wrapper
export type UnresolvedMigrateProps<UC = unknown> =
  | MigratePropsInRender<UC>
  | MigratePropsInAdmin<UC>

// The enhancing wrapper that adds defaultMigrate before calling
// block-provided migrate
export type MigrateWrapper<C, UC = unknown> = (
  props: UnresolvedMigrateProps<UC>,
) => C

// What gets passed to defaultMigrate
export type DefaultMigrateProps<UC = unknown> = {
  config: UC
}

// What gets passed to migrate by way of the enhancing wrapper
export type MigrateProps<UC = unknown> = UnresolvedMigrateProps<UC> & {
  hasHack: (s: Hack) => boolean
}

export type Migrate<C, UC = unknown> = (props: MigrateProps<UC>) => C

export type AdminProps<C> = Omit<Final.FormRenderProps<C>, 'handleSubmit'>

export type Admin<C> = (props: AdminProps<C>) => JSX.Element | null

export interface AdminInnerWrapperProps {
  children: ReactNode
  context: AdminContextFromServer
  queryClient: QueryClient
  global: boolean
}

export type AdminWrapperProps<C> = Omit<
  AdminInnerWrapperProps,
  'children' | 'global'
> & {
  name: string
  textarea: HTMLTextAreaElement
} & (
    | {
        global: true
        component: Admin<C>
        migrate: GlobalMigrateWrapper<C>
      }
    | {
        global?: false
        component: Admin<C>
        migrate: MigrateWrapper<C>
      }
  )

// blockWithInfo supplies AdminPreRenderProps. This is half of the eventual
// AdminWrapperProps, the other half is AdminRenderProps below.
export type AdminPreRenderProps<C> = {
  name: string
} & (
  | {
      global: true
      Admin: Admin<C>
      migrate: GlobalMigrateWrapper<C>
    }
  | {
      global?: false
      Admin: Admin<C>
      migrate: MigrateWrapper<C>
    }
)

// This is the other half of AdminWrapperProps, supplied when the admin
// component actually renders on the screen.
export interface AdminRenderPropsBase {
  context: AdminContextFromServer
  queryClient: QueryClient
  textarea: HTMLTextAreaElement
}
export interface AdminRenderProps extends AdminRenderPropsBase {
  root: Parameters<typeof createRoot>[0]
  render?: never
}
export interface AdminRenderPropsTest extends AdminRenderPropsBase {
  root?: never
  render: (child: ReactElement) => any
}
export interface AdminRender {
  (props: AdminRenderProps): () => void // returns cleanup
  (props: AdminRenderPropsTest): any
}

export interface Storybook<C, G> extends BlockInfo<C, G, any> {
  Admin: Admin<C>
  GlobalAdmin?: Admin<G>
}

export interface BlockRenderPropsBase {
  blockId: string
  config?: unknown
  context: BlockContext
  hydrate: boolean
  queryClient: QueryClient
  sortKey: number
  store: Store // ComponentProps<typeof ReduxProvider>['store']
}
export interface BlockRenderProps extends BlockRenderPropsBase {
  root: Element
  render?: never
  Router?: never
  routerProps?: never
}
export interface BlockRenderPropsTest extends BlockRenderPropsBase {
  root?: never
  render: (child: ReactElement) => any
  Router: any
  routerProps?: any
}
export interface BlockRender {
  (props: BlockRenderProps): () => void // returns cleanup
  (props: BlockRenderPropsTest): any
}

export interface BlockMeta<C, G, N extends string> {
  name: N
  displayName: string
  title: string
  defaultConfig: C
  defaultGlobalConfig: G
  globalMigrate: GlobalMigrateWrapper<G>
  migrate: MigrateWrapper<C>
}

export interface BlockInfo<C, G, N extends string> extends BlockMeta<C, G, N> {
  Block: Block<C, G, N> // TODO kill circular
  admin: AdminRender
  defaultConfigJson: string
  dividerAbove: boolean
  dividerBelow: boolean
  globalAdmin?: AdminRender
  render: BlockRender
  ssr: any // TODO
  storybook: Storybook<C, G>
  wrapperDiv: boolean
}

export interface BlockProps {
  // These should always be set coming from actual render, but it's nice to be
  // able to render blocks directly in Storybook without bothering.
  blockId?: string
  config?: unknown
  sortKey?: number
}

export type BlockWithoutInfo = FCx<BlockProps>

export interface Block<C, G, N extends string> extends BlockWithoutInfo {
  displayName: string
  info: BlockInfo<C, G, N>
}

export type Size = 'sm' | 'md' | 'lg'

export type Variant = 'error' | 'info' | 'success' | 'valid' | 'warning'

// See quickstartMacros.ftl
export type FreemarkerOutput = 'markdown' | 'html' | 'text'

export interface BlockConfig {
  FREEMARKER?: {
    [key: string]: FreemarkerOutput
  }
}

export interface GlobalBlockConfig {
  FREEMARKER?: {
    [key: string]: FreemarkerOutput
  }
}

export type AuthAgent = 'tizra' | 'remote'
