/**
 * @file Replacements for console.error, console.warn, console.log for prefixing
 * and other fixups.
 */

import {debugging} from './debug'
import {IS_BROWSER, IS_GRAAL, TREE} from './globals'

/**
 * Convert exception traces into a single line, rather than filling up the
 * console.
 */
function shortStack(e: unknown) {
  return e instanceof Error && typeof e.stack === 'string' ?
      e.stack.split('\n').slice(0, 2).join(' ')
    : e
}

/**
 * Convert objects to JSON, to avoid useless [object] output on SSR console.
 */
const jsonify: <T>(x: T) => T | string = x => {
  if (x && typeof x === 'object') {
    try {
      return JSON.stringify(x)
    } catch {
      // oh well
    }
  }
  return x
}

export class Logger {
  readonly #prefix: string
  readonly #enabled: boolean
  readonly #once: Set<string>
  #browser?: Logger
  #server?: Logger

  constructor(prefix: string, enabled: boolean = true) {
    this.#prefix = prefix
    this.#enabled = enabled
    this.#once = new Set()
  }

  normalize<T extends unknown[]>(args: T): T {
    return IS_GRAAL ? (args.map(shortStack).map(jsonify) as T) : args
  }

  prepare<T extends unknown[]>(args: T): T {
    let [first, ...rest] = args
    return (
      typeof first === 'string' ?
        [`${TREE}${this.#prefix}: ${first}`, ...this.normalize(rest)]
      : [`${TREE}${this.#prefix}:`, ...this.normalize(args)]) as T
  }

  log(...args: Parameters<(typeof console)['log']>) {
    if (this.#enabled) {
      console.log(...this.prepare(args))
    }
  }

  warn(...args: Parameters<(typeof console)['warn']>) {
    if (this.#enabled) {
      console.warn(...this.prepare(args))
    }
  }

  error(...args: Parameters<(typeof console)['error']>) {
    if (this.#enabled) {
      console.error(...this.prepare(args))
    }
  }

  assert<T>(cond: T, ...args: Parameters<(typeof console)['error']>): T {
    if (!cond) {
      this.error(...args)
    }
    return cond
  }

  aver<T>(cond: T) {
    if (cond) {
      return (...args: Parameters<(typeof console)['error']>) => {
        this.error(...args)
        return cond
      }
    }
  }

  // eslint doesn't like that this conditionally returns a value.
  // eslint-disable-next-line getter-return
  get debug() {
    if (this.#enabled && debugging()) {
      return this._debug.bind(this)
    }
  }
  _debug(...args: Parameters<(typeof console)['debug']>) {
    console.debug(...this.prepare(args))
  }

  pipe(tag: string) {
    return <T>(arg: T): T => {
      this.debug?.(tag, arg)
      return arg
    }
  }

  tap<T>(arg: T): T {
    this.debug?.(arg)
    return arg
  }

  logger(prefix: string, enabled: boolean = this.#enabled) {
    return new Logger(`${this.#prefix}:${prefix}`, enabled)
  }

  trace<F extends (...args: any) => any>(tag: string, fn: F) {
    return ((...args: any) => {
      const ret = fn(...args)
      this.debug?.(tag, {args, ret})
      return ret
    }) as F
  }

  once(key: string) {
    if (!this.#once.has(key)) {
      this.#once.add(key)
      return this
    }
  }

  get browser() {
    return (this.#browser ||= new Logger(
      this.#prefix,
      this.#enabled && IS_BROWSER,
    ))
  }

  get server() {
    return (this.#server ||= new Logger(
      this.#prefix,
      this.#enabled && !IS_BROWSER,
    ))
  }
}

export const logger = (prefix: string, enabled?: boolean) =>
  new Logger(prefix, enabled)
